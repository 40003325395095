<template>
    <div class="logs">
        <div class="header">
            <div class="timestamp">Timestamp</div>
            <div class="message">Message</div>
            <div class="location">Location</div>
            <div class="ip">IP address</div>
        </div>
        <div v-for="log in logs" :key="log.timestamp" class="log">
            <div class="timestamp">{{ log.timestamp | datetime }}</div>
            <div class="message">{{ log.message }}</div>
            <div class="location">{{ log.city }}{{ log.city ? ',' : ''}} {{ log.region }}</div>
            <div class="ip">{{ log.ip }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Logs',
    data() {
        return {
            logs: []
        }
    },
    created() {
        this.axios.get('/admin/logs/')
        .then(resp => {
            this.logs.push(...resp.data.results)
        })
        .catch(() => {})
    },
    filters: {
        datetime(val) {
            return new Date(val).toLocaleDateString('us-en', {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'})
        }
    }
}
</script>

<style scoped>
.logs {
    font-size: .7rem;
}
.header {
    font-weight: bold;
    white-space: nowrap;
}
.log {
    border-bottom: 1px solid gray;
}
.timestamp,
.message,
.location,
.ip {
    display: inline-block;
    padding: .2rem 1rem;
    overflow-x: hidden;
}
.timestamp {
    width: 20%;
}
.message {
    width: 50%;
}
.location {
    width: 15%;
}
.ip {
    width: 15%;
}
@media (max-width: 768px) {
    .timestamp,
    .message,
    .location,
    .ip {
        display: block;
        width: 100%;
    }
    .header {
        display: none;
    }
}
</style>